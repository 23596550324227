import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";
// import faviconn from "../assets/images/logo/favicon.png";
import faviconn from "../assets/images/logo/passivation-logo.png";
import "./Header.css";
import "./Bsnav.css";

const Header = ()=>{
  const [isActive, setIsActive] = useState(false);

  const handleButtonClick = () => {
    setIsActive(!isActive);
  };

  const [isSticked, setIsSticked] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setIsSticked(scrollPosition > 0);
        setIsVisible(scrollPosition > 30);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


   return (
     <>
       <header className="header header-before-off">
         <div className="main-wrapper">
           <div className={`top-nav navbar navbar-expand-lg bsnav bsnav-sticky ${
               isSticked ? "sticked" : ""
             } ${isVisible ? "in" : ""} bsnav-sticky-slide bsnav-transparent`}
           >
             <Container>
               <Link className="navbar-brand" to="">
                 <Image
                   src={faviconn}
                   className="logo-display"
                   alt="company logo"
                 />
                 <Image
                   src={faviconn}
                   className="logo-scrolled"
                   alt="company logo"
                 />
               </Link>
               {/* <Button className="navbar-toggler toggler-spring"><span className="navbar-toggler-icon"></span></Button> */}
               <Button className={`navbar-toggler toggler-spring ${isActive ? "active" : "" }`}
                 onClick={handleButtonClick} >
                 <span className="navbar-toggler-icon"></span>
               </Button>
               <div className="collapse navbar-collapse justify-content-md-end">
                 <ul className="navbar-nav navbar-mobile mr-0">
                   <li className="nav-item">
                     <Link className="nav-link" to="/">
                       Home
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link" to="/about">
                       About Us
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link" to="/services">
                       Services
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link" to="/contact">
                       Contact Us
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link" to="/jobs">
                       Jobs
                     </Link>
                   </li>
                 </ul>
               </div>
               <div className="search-cart nav-profile ml-40">
                 <Link to="" className="btn-1 btn-circle btn-gradient">
                   Let's Begin
                 </Link>
               </div>
             </Container>
           </div>
           {/* <div className={`bsnav-mobile ${isActive ? 'in' : ''}`}>
          <div className="bsnav-mobile-overlay" onClick={handleButtonClick}></div>
          <div className="navbar">
            <div className="collapse navbar-collapse justify-content-md-end">
              <ul className="navbar-nav navbar-mobile mr-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services">Services</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/jobs">Jobs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
           <div className={`mobile-navbar ${isActive ? 'in' : ''}`}>
           <div class={`navbar`}>
           <ul className="navbar-nav navbar-mobile mr-0">
               <li className="nav-item">
                 <Link className="nav-link" to="/">
                   Home
                 </Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link" to="/about">
                   About Us
                 </Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link" to="/services">
                   Services
                 </Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link" to="/contact">
                   Contact Us
                 </Link>
               </li>
               <li className="nav-item">
                 <Link className="nav-link" to="/jobs">
                   Jobs
                 </Link>
               </li>
             </ul>
           </div>
           </div>
         </div>
       </header>
     </>
   );
}

export default Header;