import './Style.css';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import serviceHero from '../assets/images/pictures/standard-quality-control-collage-concept.webp';
import icon3d5 from '../assets/images/icon/icon-3d-5.webp';
import icon3d11 from '../assets/images/icon/icon-3d-11.webp';
import icon3d9 from '../assets/images/icon/icon-3d-9.webp';
import icon3d3 from '../assets/images/icon/icon-3d-3.webp';
import shape16 from '../assets/images/shape/shape-16.png';

const Services = () => {
  return (
    <>
      <main className="main">
        {/* Start Breadcrumb */}
        <div
          className="site-breadcrumb"
          style={{ background: `url(${serviceHero})` }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">
              Elevating Businesses Through Technological Excellence.
            </h2>
            <ul className="breadcrumb-menu clearfix">
              <li className="crumb-li">
                <Link to="/">Home</Link>
              </li>
              <li className="active">Our Services</li>
            </ul>
          </div>
        </div>
        {/* End Breadcrumb */}
        {/* Start Service */}
        <div className="service-area pos-rel service-padding">
          <div className="container">
            <div className="title-service mb-40">
              <div className="row align-items-center">
                <div className="col-xl-8">
                  <div className="service-title">
                    <span className="hero-sub-title text-grad mb-20">
                      What we offer
                    </span>
                    <h2 className="heading-1 mb-0">
                      Service we offer for promote <br /> your Business
                    </h2>
                  </div>
                </div>
                <div className="col-xl-4">
                  <p className="mb-0">
                    Our team at Ingentecho is composed of dedicated
                    professionals who are passionate about leveraging technology
                    to solve complex challenges. With a focus on service
                    excellence and innovation, we strive to exceed our clients'
                    expectations every step of the way.
                  </p>
                </div>
              </div>
            </div>
            <Container>
              <Row className="service-wpr-2 grid-4 mb-4">
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d5} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Enterprise software solution
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d11} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Technical Service Provider
                      </h4>
                      <Link
                        to="/service/technical"
                        className="btn-1 btn-sm btn-circle"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d9} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Custom Software Development
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d3} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Best UI/UX Development Service
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="service-wpr-2 grid-4">
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d5} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Enterprise software solution
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d11} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Technical Service Provider
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d9} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Custom Software Development
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="service-box-2">
                    <Image
                      src={shape16}
                      className="service-shape-16"
                      alt="no image"
                    />
                    <div className="service-pic-2">
                      <Image src={icon3d3} alt="no image" />
                    </div>
                    <div className="service-desc-2">
                      <h4 className="heading-4 mt-3">
                        Best UI/UX Development Service
                      </h4>
                      <Link to="#" className="btn-1 btn-sm btn-circle">
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* End Service */}
      </main>
    </>
  );
};

export default Services;
