import './Style.css';
import './Timeline.css';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import serviceBanner from '../assets/images/pictures/corporate-management-strategy-solution-branding-concept.jpeg';
import servicetopImage from '../assets/images/vector/service-vector.jpg';
import servicekeyimg1 from '../assets/images/vector/service-key-img1.png';
import servicekeyimg2 from '../assets/images/vector/service-key-img2.png';
import servicekeyimg3 from '../assets/images/vector/service-key-img3.png';
import servicekeyimg4 from '../assets/images/vector/service-key-img4.png';
import { FaHandHoldingUsd } from 'react-icons/fa';
const Servicesingle = () => {
  const params = useParams();
  const { service } = params;
  console.warn(service);
  const events = [
    { subTitle: 'STEP ONE', title: 'Project planning', description: "project planning is more iterative and flexible compared to traditional methods. It involves creating a high-level plan that outlines the project's vision, goals, and initial requirements. Establishing the project's scope, setting up the Agile framework (such as Scrum or Kanban), defining user roles, and creating an initial product backlog." },
    { subTitle: 'STEP TWO', title: 'Requirements analysis', description: "Requirements are gathered continuously and iteratively, with a focus on creating user stories that represent the needs of the end-users. Collaborating with stakeholders to gather and refine requirements, prioritizing the product backlog, and creating user stories with acceptance criteria." },
    { subTitle: 'STEP THREE', title: 'Design and development', description: "Design and development happen in short, iterative cycles called sprints (in Scrum) or continuous flow (in Kanban), allowing for frequent adjustments based on feedback. Designing system architecture and components, writing code, conducting peer reviews, and performing unit testing within each iteration." },
    { subTitle: 'STEP FOUR', title: 'Testing', description: "Testing is integrated into each iteration, ensuring that features are tested as they are developed. This is often referred to as continuous testing or test-driven development (TDD). Executing automated tests, performing manual tests, conducting regression tests, and validating that the developed features meet the acceptance criteria." },
    { subTitle: 'STEP FIVE', title: 'Client feedback and iteration', description: "Client feedback is solicited frequently and used to inform subsequent iterations, ensuring the product evolves in line with customer needs and market changes. Demonstrating completed features to stakeholders, collecting feedback, updating the product backlog with new requirements or changes, and reprioritizing tasks." },
    { subTitle: 'STEP SIX', title: 'Release and maintenance', description: "Releases are more frequent and incremental, allowing for continuous delivery of value to customers. Maintenance is ongoing, with the team responding to issues and incorporating improvements. Deploying increments to production, monitoring the system for issues, fixing bugs, performing enhancements, and planning for future iterations based on user feedback and system performance." }
  ];
  return (
    <>
      <main className="main">
        {/* Start Breadcrumb */}
        <div
          className="site-breadcrumb"
          style={{ background: `url(${serviceBanner})` }}
        >
          <Container>
            <h2 className="breadcrumb-title">
              Streamline Your Success with Our Expert IT Solutions
            </h2>
            <ul className="breadcrumb-menu clearfix">
              <li className="crumb-li">
                <Link to="/">Home</Link>
              </li>
              <li className="active">Technical</li>
            </ul>
          </Container>
        </div>
        {/* <!-- End Breadcrumb --> */}

        {/* Start Contact Us */}
        <div className="service-pg pos-rel service-padding">
          <Container className="servicepage-content">
          <Row className=' d-flex align-items-center'>
            <Col xl={5}>
              <div className='service-article-img'>
               <Image src={servicetopImage}/>
              </div>
            </Col>
            <Col xl={7}>
              <div>
                <h2>Technical Service Porvider </h2>
                    <p>
                      Our team at Ingentecho is composed of dedicated
                      professionals who are passionate about leveraging
                      technology to solve complex challenges. With a focus on
                      service excellence and innovation, we strive to exceed our
                      clients' expectations every step of the way.
                    </p>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xl={9}>
                <Row>
                </Row>
                
              </Col>
              <Col xl={3}>
                <div className="services-list">
                  <div className="list-title">
                    <h6>Services</h6>
                  </div>
                  <div className="services-list-items">
                    <ul className="list-item-list ps-0">
                      <li>
                        <Link to="#">
                          <FaHandHoldingUsd />
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">About Us</Link>
                      </li>
                      <li>
                        <Link to="#">Our Services</Link>
                      </li>
                      <li>
                        <Link to="#">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row> */}
          </Container>
          <div className='service-key'>
              <Row>
                <div className='service-key-title text-center mb-30'>
                  <h2>Key Benefits</h2>
                </div>
                <Col xl={6}>
                 <div className='service-key-box mb-10 p-3'>
                  <div className='service-key-imges'>
                    <Image className='service-key-img' src={servicekeyimg1}/>
                  </div>
                  <div className='service-key-content'>
                    <p>
                    We don’t want to help you build a full-featured product only to discover the 
                    market doesn’t want it. We want you to succeed. That’s why we focus on the vital 
                    features needed to test your product’s main assumptions – leaving unnecessary 
                    features behind, or saving them for later iterations.
                    </p>
                  </div>
                 </div>
                </Col>
                <Col xl={6}>
                 <div className='service-key-box mb-10 p-3'>
                  <div className='service-key-imges'>
                    <Image className='service-key-img' src={servicekeyimg1}/>
                  </div>
                  <div className='service-key-content'>
                    <p>
                    We don’t want to help you build a full-featured product only to discover the 
                    market doesn’t want it. We want you to succeed. That’s why we focus on the vital 
                    features needed to test your product’s main assumptions – leaving unnecessary 
                    features behind, or saving them for later iterations.
                    </p>
                  </div>
                 </div>
                </Col>
                <Col xl={6}>
                 <div className='service-key-box mb-10 p-3'>
                  <div className='service-key-imges'>
                    <Image className='service-key-img' src={servicekeyimg1}/>
                  </div>
                  <div className='service-key-content'>
                    <p>
                    We don’t want to help you build a full-featured product only to discover the 
                    market doesn’t want it. We want you to succeed. That’s why we focus on the vital 
                    features needed to test your product’s main assumptions – leaving unnecessary 
                    features behind, or saving them for later iterations.
                    </p>
                  </div>
                 </div>
                </Col>
                <Col xl={6}>
                 <div className='service-key-box mb-10 p-3'>
                  <div className='service-key-imges'>
                    <Image className='service-key-img' src={servicekeyimg1}/>
                  </div>
                  <div className='service-key-content'>
                    <p>
                    We don’t want to help you build a full-featured product only to discover the 
                    market doesn’t want it. We want you to succeed. That’s why we focus on the vital 
                    features needed to test your product’s main assumptions – leaving unnecessary 
                    features behind, or saving them for later iterations.
                    </p>
                  </div>
                 </div>
                </Col>
              </Row>
            </div>
            <div className='service-work-track'>
               <div className='service-track-title text-center mb-30'>
                  <h2>How it work</h2>
               </div>
               <div className="service-timeline">
                {events.map((event, index) => (
                 <div className={`timeline-container ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
                   <div className="timeline-content">
                    <h5>{event.subTitle}</h5>
                    <h2>{event.title}</h2>
                    <p>{event.description}</p>
                    <span className={`timelineArrow ${index % 2 === 0 ? 'left-arrow' : 'right-arrow'}`} key={index}></span>
                   </div>
                 </div>
                ))}
               </div>
             </div>
        </div>
        {/* End Contact Us  */}

      </main>
    </>
  );
};

export default Servicesingle;
