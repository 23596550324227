import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './header-footer/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './header-footer/Footer';
import Index from './pages/Index';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Jobs from './pages/Jobs';
import Servicesingle from './pages/Servicesingle';

function App() {
  return (
    <BrowserRouter>
    <Header/>
     <Routes>
      <Route path='/' element={<Index/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/service/:slug' element={<Servicesingle/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/jobs' element={<Jobs/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
