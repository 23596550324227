import { Tabs, Tab } from 'react-bootstrap';
import './Style.css';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Modal,
} from 'react-bootstrap';
import jobBanner from '../assets/images/vector/design-html-web-design-template-concept.webp';
import job1 from '../assets/images/vector/job-1.webp';
import job2 from '../assets/images/vector/job-2.webp';
import job3 from '../assets/images/vector/job-3.webp';
import job4 from '../assets/images/vector/job-4.webp';
import { Link } from 'react-router-dom';
import {
  FaArrowRight,
  FaClipboardUser,
  FaUserClock,
  FaUserGroup,
  FaLocationDot,
  FaSuitcase,
  FaHeartPulse,
} from 'react-icons/fa6';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useState } from 'react';

const TabContent = ({ title }) => {
  const [toggleClass, setToggleClass] = useState(false);
  const [formModalShow, setFormModalShow] = useState(false);

  const handleClick = (e) => {
    setToggleClass(!toggleClass);
    // console.log(e);
  };

  return (
    <div className="tab-item">
      <div className={`panel ${toggleClass ? 'panel-open' : 'panel-close'}`}>
        <div className="panel-header">
          <div className="pnl-header-content">
            <h4>{title}</h4>
            <div className="key-type d-flex">
              <span className="type-exp">
                <FaClipboardUser className="key-type-icon" />
                Designer
              </span>
              <span className="type-exp">
                <FaUserClock className="key-type-icon" />
                0-3 Years Experience
              </span>
              <span className="type-exp">
                <FaUserGroup className="key-type-icon" />3 Vacancies
              </span>
              <span className="type-exp">
                <FaLocationDot className="key-type-icon" />
                Patna
              </span>
            </div>
            <span className="d-flex align-items-center">
              <FaSuitcase className="key-type-icon" />
              Full Time
            </span>
          </div>
          <div className="arrow-btn">
            <Button className={`arr-btn`} onClick={handleClick}>
              <FaArrowRight />
            </Button>
          </div>
        </div>
        <div className={`panel-content ${toggleClass ? 'panel-open' : ''}`}>
          <div className="skill-content">
            <span className="panel-content-header">Skills</span>
            <ul className="skill-keypoint">
              <li>B.tech</li>
              <li>React Js</li>
              <li>Node</li>
            </ul>
          </div>
          <div className="apply-btn">
            <Button
              className="btn-apply"
              onClick={() => setFormModalShow(true)}
            >
              Apply Now
            </Button>
            <MyVerticallyCenteredModal
              show={formModalShow}
              onHide={() => setFormModalShow(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function MyVerticallyCenteredModal(props) {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Button className="formClose" onClick={props.onHide}>
        <AiFillCloseCircle className="close-ico" />
      </Button>
      <Modal.Header className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Interested in Joining Us?</h4>
          <p>Please fill out the form below</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>LinkedIn Url</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="disabledSelect">Current CTC</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--Select your current CTC--</option>
                  <option value="1">3LPA</option>
                  <option value="2">4LPA</option>
                  <option value="3">5LPA</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="disabledSelect">Expectred CTC</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>--Select your expected CTC--</option>
                  <option value="1">6 LPA</option>
                  <option value="2">7 LPA</option>
                  <option value="3">8 LPA</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Why Should We Hire You?</Form.Label>
                <Form.Control as="textarea" placeholder="Enter email" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Your Resume</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            </Col>
          </Row>
          <Button className="btn-apply" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const Jobs = () => {
  return (
    <>
      <main className="main">
        {/* Start Breadcrumb */}
        <div
          className="site-breadcrumb"
          style={{ background: `url(${jobBanner})` }}
        >
          <Container>
            <h2 className="breadcrumb-title">
              Connecting Solutions, One Click Away.
            </h2>
            <ul className="breadcrumb-menu clearfix">
              <li className="crumb-li">
                <Link to="/">Home</Link>
              </li>
              <li className="active">Jobs</li>
            </ul>
          </Container>
        </div>
        {/* <!-- End Breadcrumb --> */}
        <Container>
          <div className="title-service mb-40 service-padding">
            <Row className="align-items-center">
              <Col xl={7}>
                <div className="left-content">
                  <h1>
                    Power Up Your <span>Career in IT</span> with InGen
                  </h1>
                  <p className="mt-30 mb-30">
                    At InGen, we’re always looking for <span>enthusiastic</span>{' '}
                    and
                    <span> talented</span> IT professionals who can help
                    businesses scale to new heights. Just like we do!
                  </p>
                  <Link className="btn-apply" to="#job-panel">
                    Join Us Now!{' '}
                  </Link>
                </div>
              </Col>
              <Col xl={5}>
                <div className="about-pos-2">
                  <div className="bg-job round-move "></div>
                </div>
                <div className="about-left-2-pic about-grid job-grid-img">
                  <div className="about-left-2-1 text-right">
                    <Image
                      src={job1}
                      className="mb-4 about-img1"
                      alt="no image"
                    />
                    <Image src={job2} className="about-img2" alt="no image" />
                  </div>
                  <div className="about-left-2-2">
                    <Image
                      src={job3}
                      className="mb-4 about-img2"
                      alt="no image"
                    />
                    <Image src={job4} className="about-img1" alt="no image" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        {/* facility start */}
        <div className="job-banner mb-40">
          <Row>
            <Col xl={3}>
              <div className="job-facility">
                <FaHeartPulse className="facility-ico" />
                <h3>Competitive salary & bonus</h3>
              </div>
            </Col>
            <Col xl={3}>
              <div className="job-facility">
                <FaHeartPulse className="facility-ico" />
                <h3>Competitive salary & bonus</h3>
              </div>
            </Col>
            <Col xl={3}>
              <div className="job-facility">
                <FaHeartPulse className="facility-ico" />
                <h3>Competitive salary & bonus</h3>
              </div>
            </Col>
            <Col xl={3}>
              <div className="job-facility">
                <FaHeartPulse className="facility-ico" />
                <h3>Competitive salary & bonus</h3>
              </div>
            </Col>
          </Row>
        </div>
        {/* facility end */}

        {/* tab start */}

        <Container>
          <Row>
            <div className="contact-title mb-40">
              <h2 className="heading-3">Available positions</h2>
              <p className="mb-0">Feel free to get in touch with us!</p>
            </div>
          </Row>
          <Row id="job-panel">
            <Tabs
              defaultActiveKey="all"
              id="uncontrolled-tab-example"
              className="mb-3 tab-list"
            >
              <Tab eventKey="all" title="All" className="tab-items">
                <TabContent title="Content Writer" />
                <TabContent title="Content Writer" />
              </Tab>
              <Tab
                eventKey="software development"
                title="Software Development"
                className="tab-items"
              >
                <TabContent title="Content Writer" />
              </Tab>
              <Tab
                eventKey="digital marketing"
                title="Digital Marketing"
                className="tab-items"
              >
                <TabContent title="Content Writer" />
              </Tab>
            </Tabs>
          </Row>
        </Container>

        {/* tab end */}
      </main>
    </>
  );
};

export default Jobs;
