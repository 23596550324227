import { Col, Container, Image, Row } from 'react-bootstrap';
import one from '../assets/images/ui/1.png';
import two from '../assets/images/ui/2.png';
import three from '../assets/images/ui/3.jpg';
import man from '../assets/images/ui/man.png';
import chooseTeam from '../assets/images/ui/why-choose-team-removebg.png';
import guarantee from '../assets/images/ui/why-choose-guarantee-removebg.png';

const ChooseUs = () => {
  return (
    <div className="why-area-2 mt-5 pb-5">
      <Container>
        <div className="why-wpr-2">
          <Row>
            <Col xl="7">
              <div className="why-left-2 pr-60">
                <div className="why-left-up mb-40">
                  <h2 className="heading-1">Why choose us?</h2>
                  <p className="mb-0 text-justify">
                    We have highly skilled engineers with excellent technical
                    knowledge and experience in using latest software standards,
                    tools, platforms, frameworks and technologies and we invest
                    continuously in training and education, to be able to
                    respond to any new technology challenges and demands from
                    our clients.
                  </p>
                </div>
                <Row className="why-option-2 grid-2">
                  <Col md="6">
                    <div className="why-option-single-2">
                      <div className="why-option-up-2">
                        <h5 className="heading-5  mb-0">Experience Team</h5>
                        <i className="fa-solid fa-microchip"></i>
                      </div>
                      <div className="why-option-img">
                        <Image
                          className="why-choose-img-one"
                          src={chooseTeam}
                        />
                      </div>
                      <div className="why-option-bottom-2">
                        <p className="mb-0 text-justify">
                          Our experienced senior team collaborates closely with
                          you throughout our engagement. We take great pride in
                          our work and strive for consistent excellence. This
                          leads to the development of strategic insights and
                          recommendations that you can support with confidence.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="why-option-single-2">
                      <div className="why-option-up-2">
                        <h5 className="heading-5  mb-0">
                          Satisfaction Guaranteed
                        </h5>
                        <i className="fa-solid fa-coins"></i>
                      </div>
                      <div className="why-option-img">
                        <Image className="why-choose-img-one" src={guarantee} />
                      </div>
                      <div className="why-option-bottom-2">
                        <p className="mb-0 text-justify">
                          No matter how far of the beaten track it is we can
                          help. Our team of knowledgeable staff are well versed
                          in organising transport and accommodation to the more
                          obscure locations as well as the well travelled
                          business routes, we know we can deliver your perfect,
                          hassle free travel co-ordination.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl="5">
              <div className="why-right-2 element-center">
                <div className="why-right-pic-2 pos-rel ">
                  <Image src={man} className="why-pic-1" alt="no image" />
                  <Image src={one} className="why-pic-2" alt="no image" />
                  <Image src={two} className="why-pic-3" alt="no image" />
                  <Image src={three} className="why-pic-4" alt="no image" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default ChooseUs;
