import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import shape34 from '../assets/images/bg/shape-34.png';
import { useState } from 'react';
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    web: '',
    subject: '',
    comments: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
  };

  return (
    <div
      className="contact-area hero-bg black-overlay mt-5"
      style={{ backgroundImage: `url(${shape34})` }}
    >
      <Container>
        <Row>
          <Col lg={8} className="offset-lg-2">
            <div className="site-title text-center">
              <h5 className="hero-sub-title text-grad">Contact us</h5>
              <h2 className="up-title">Lets talk about your project</h2>
              <div className="title-devider"></div>
            </div>
          </Col>
        </Row>
        <div className="contact-wpr grid-2">
          <Row>
            <Col lg={6}>
              <div className="contact-left contact-home">
                <Form onSubmit={handleSubmit} className="contact-form">
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="name" className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Your Full Name*"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="email" className="mb-4">
                        <Form.Control
                          type="email"
                          placeholder="Your Email Address*"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="phone" className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="comments">
                <Form.Control
                  as="textarea"
                  className="msg-form"
                  rows={4}
                  placeholder="Your Message..."
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <div className="contact-sub-btn text-center">
                <Button type="submit" className="btn-submit btn-gradient mt-3">
                  Send Message <i className="fas fa-chevron-right"></i>
                </Button>
              </div>
              <div className="alert-notification">
                <div id="message" className="alert-msg"></div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default ContactUs;
