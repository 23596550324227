import './Style.css';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import contactBanner from '../assets/images/pictures/side-view-man-with-smartphone-laptop.jpeg';
import formimg from '../assets/images/icon/contact-msg-removebg-preview.webp';
import addreBg from '../assets/images/bg/contct-removebg-preview.webp';
import globeBg from '../assets/images/bg/globe-removebg-preview.webp';
import locationimg from '../assets/images/icon/gps.png';
import { FaPhoneVolume, FaLocationDot, FaEnvelope } from 'react-icons/fa6';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    web: '',
    subject: '',
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    console.log(formData);
  };

  return (
    <>
      <main className="main">
        {/* Start Breadcrumb */}
        <div
          className="site-breadcrumb"
          style={{ background: `url(${contactBanner})` }}
        >
          <Container>
            <h2 className="breadcrumb-title">
              Connecting Solutions, One Click Away.
            </h2>
            <ul className="breadcrumb-menu clearfix">
              <li className="crumb-li">
                <Link to="/">Home</Link>
              </li>
              <li className="active">Contact Us</li>
            </ul>
          </Container>
        </div>
        {/* <!-- End Breadcrumb --> */}

        {/* Start Contact Us */}
        <div className="contact-us pos-rel service-padding">
          <Container>
            <Row>
              <div className="contact-title mb-40">
                <h3 className="heading-3">
                  Got questions or ready to kickstart your project? Drop us a
                  line!
                </h3>
                <p className="mb-0">
                  Feel free to get in touch with us! Whether you have a query
                  about our services, need technical support, or want to discuss
                  a potential collaboration, our team is here to help. You can
                  reach us via email, phone, or by filling out the contact form
                  below. We strive to respond promptly to all inquiries and look
                  forward to hearing from you.
                </p>
              </div>
            </Row>
            <div className="contact-form">
              <Row>
                <Col xl={6}>
                  <div className="contact-img">
                    {/* <span className="skew-bg"></span> */}
                    <Image src={formimg} />
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="contact-ads">
                    <Form onSubmit={handleSubmit} className="contact-frm">
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="name" className="mb-4">
                            <Form.Control
                              type="text"
                              placeholder="Your Full Name*"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="email" className="mb-4">
                            <Form.Control
                              type="email"
                              placeholder="Your Email Address*"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="phone" className="mb-4">
                            <Form.Control
                              type="text"
                              placeholder="Phone Number"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="phone" className="mb-4">
                            <Form.Control
                              type="text"
                              placeholder="Phone Number"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group controlId="comments">
                            <Form.Control
                              as="textarea"
                              className="msg-form"
                              rows={4}
                              placeholder="Your Message..."
                              name="comments"
                              value={formData.comments}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <div className="contact-sub-btn text-center">
                            <Button
                              type="submit"
                              className="btn-submit btn-gradient mt-3"
                            >
                              Send Message{' '}
                              <i className="fas fa-chevron-right"></i>
                            </Button>
                          </div>
                          <div className="alert-notification">
                            <div id="message" className="alert-msg"></div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <div className="contact-title mb-20 mt-30">
                <h3 className="heading-3">Contact Information</h3>
              </div>
            </Row>
            <div className="contact-us-wpr contact-page">
              <Image src={addreBg} className="adreess-bg net-bg" />
              <Image src={globeBg} className="globe-bg adreess-bg" />
              <Row>
                <Col xl="5">
                  <div className="addr-box">
                    <div className="addr-box-single">
                      <div className="addr-icon">
                        <FaLocationDot />
                      </div>
                      <div className="addr-desc">
                        <h5>Office Address</h5>
                        <p className="mb-0">
                          3rd Floor Maharani Complex,
                          <br />
                          Near Hari Om Petrol Pump,
                          <br />
                          Anisabad, Patna-800002.
                        </p>
                      </div>
                    </div>
                    <div className="addr-box-single">
                      <div className="addr-icon">
                        <FaPhoneVolume />
                      </div>
                      <div className="addr-desc">
                        <h5>Phone Number</h5>
                        <p className="mb-0">+91 8290997456</p>
                      </div>
                    </div>
                    <div className="addr-box-single">
                      <div className="addr-icon">
                        <FaEnvelope />
                      </div>
                      <div className="addr-desc">
                        <h5>Email Us</h5>
                        <p className="mb-0">info@ingentechno.com</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl="7">
                  <div className="contact-location">
                    <Image src={locationimg} />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* End Contact Us  */}

        {/* Start Google Map */}
        {/* <div className="g-map-area">
          <div className="g-map--wrapper text-center">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3645.133068555471!2d91.08454181482016!3d23.99107768447128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3754051b50ebac3f%3A0x735c1cc426d8ebb!2sNatai%20Bodtoli%20Bazar%2C%20Natai%2C%2C%20Brahmanbaria!5e0!3m2!1sen!2sbd!4v1594548160557!5m2!1sen!2sbd"></iframe>
          </div>
        </div> */}
        {/* End Google Map  */}
      </main>
    </>
  );
};

export default Contact;
