import { Col, Container, Image, Row } from 'react-bootstrap';

import about2 from '../assets/images/bg/bg-about2.jpeg';
import about21 from '../assets/images/pictures/about-21.webp';
import about22 from '../assets/images/pictures/about-22.webp';
import about23 from '../assets/images/pictures/about-23.webp';
import about24 from '../assets/images/pictures/about-24.webp';

import { FaBusinessTime, FaRss } from 'react-icons/fa6';

const About = () => {
  return (
    <div className="about-area-2 pt-4 pb-5 pos-rel">
      <Container>
        <Row className="about-wpr grid-2">
          <Col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            className="about-left-2 pos-rel pl-50"
          >
            <div className="about-pos-2">
              <Image src={about2} className="bg-about" alt="no image" />
            </div>
            <div className="about-left-2-pic about-grid">
              <div className="about-left-2-1 text-right">
                <Image
                  src={about21}
                  className="mb-4 about-img1"
                  alt="no image"
                />
                <Image src={about24} className="about-img2" alt="no image" />
              </div>
              <div className="about-left-2-2">
                <Image
                  src={about23}
                  className="mb-4 about-img2"
                  alt="no image"
                />
                <Image src={about22} className="about-img1" alt="no image" />
              </div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="6" sm="6" className="about-right-2 ps-5">
            <h5 className="hero-sub-title text-grad mb-20">About Us</h5>
            <h2 className="heading-1">
              Choose The Best IT Service Industry in the globe
            </h2>
            <p>
              Welcome to Passivation Technologies, where innovation merges with technology to
              empower businesses worldwide. Founded with a vision in 2024,
              Passivation technologies is a dynamic company specializing in service-based
              solutions, production, and cutting-edge web development.
            </p>
            <div className="about-2-opt grid-2">
              <Row>
                <Col md="6">
                  <div className="about-2-opt-single">
                    <FaBusinessTime className="ti-home" />
                    <h5 className="heading-4">Business Consultation</h5>
                  </div>
                </Col>
                <Col md="6">
                  <div className="about-2-opt-single ">
                    <FaRss className="ti-rss-alt" />
                    <h5 className="heading-4">Team Management</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default About;
