import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import bgPromo from '../assets/images/pictures/bg-promo.webp';
import pattern1 from '../assets/images/shape/pattern-1.webp';

const Promo = () => {
  return (
    <div
      className="promo-area de-padding pos-rel bg-overlay overflow-hidden mt-5"
      style={{ backgroundImage: `url(${bgPromo})` }}
    >
      <Image
        src={pattern1}
        alt="Pattern"
        className="pattern-1"
        loading="lazy"
      />
      <div className="promo-wpr promo-margin">
        <Row>
          <Col xl="5">
            <div className="promo-left element-center">
              <div className="pl">
                <Link to="#" className="item popup-youtube play-bt">
                  <FaPhoneVolume className="ti-control-play" />
                </Link>
              </div>
            </div>
          </Col>
          <Col xl="7">
            <div className="promo-right">
              <div className="promo-right-content">
                <h5 className="heading-5 mb-3">
                  Contact Our Agent For Any kind of IT Business Help (24/7
                  Available)
                </h5>
                <span className="promo-number">+91 8290997456</span>
                <Link to="#" className="btn-1 btn-circle btn-gradient mt-3">
                  Contact Us
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Promo;
