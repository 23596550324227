import { Col, Container, Image, Row } from 'react-bootstrap';

import icon3d5 from '../assets/images/icon/icon-3d-5.webp';
import icon3d11 from '../assets/images/icon/icon-3d-11.webp';
import icon3d9 from '../assets/images/icon/icon-3d-9.webp';
import icon3d3 from '../assets/images/icon/icon-3d-3.webp';
import shape17 from '../assets/images/shape/shape-17.png';
import shape16 from '../assets/images/shape/shape-16.png';
import { Link } from 'react-router-dom';
import { FaAnglesRight } from 'react-icons/fa6';
const OurServices = () => {
  return (
    <div className="service-area-2 pos-rel mb-5">
      <Image src={shape17} className="service-shape-17" alt="no image" />
      <Container>
        <Row>
          <div className="site-title text-center">
            <h2>Our Services</h2>
            <p className="mb-0 mt-5">
              As a reputed IT firm, we understand that our clients have various
              demands in software development. Moreover, to meet all those
              demands you must possess expertise in all the various languages
              and platform. Below are some of the platform in which we provide
              our services.
            </p>
          </div>
        </Row>
        <Row className="service-wpr-2 grid-4">
          <Col xl="3">
            <div className="service-box-2">
              <Image
                src={shape16}
                className="service-shape-16"
                alt="no image"
              />
              <div className="service-content">
                <div className="service-pic-2">
                  <Image src={icon3d5} alt="no image" />
                </div>
                <div className="service-desc-2">
                  <h4 className="heading-4 mt-3">
                    Enterprise software solution
                  </h4>
                  <Link to="#" className="btn-1 btn-sm btn-circle">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3">
            <div className="service-box-2">
              <Image
                src={shape16}
                className="service-shape-16"
                alt="no image"
              />
              <div className="service-pic-2">
                <Image src={icon3d11} alt="no image" />
              </div>
              <div className="service-desc-2">
                <h4 className="heading-4 mt-3">Technical Service Provider</h4>
                <Link to="#" className="btn-1 btn-sm btn-circle">
                  Read More
                </Link>
              </div>
            </div>
          </Col>
          <Col xl="3">
            <div className="service-box-2">
              <Image
                src={shape16}
                className="service-shape-16"
                alt="no image"
              />
              <div className="service-pic-2">
                <Image src={icon3d9} alt="no image" />
              </div>
              <div className="service-desc-2">
                <h4 className="heading-4 mt-3">Custom Software Development</h4>
                <Link to="#" className="btn-1 btn-sm btn-circle">
                  Read More
                </Link>
              </div>
            </div>
          </Col>
          <Col xl="3">
            <div className="service-box-2">
              <Image
                src={shape16}
                className="service-shape-16"
                alt="no image"
              />
              <div className="service-pic-2">
                <Image src={icon3d3} alt="no image" />
              </div>
              <div className="service-desc-2">
                <h4 className="heading-4 mt-3">
                  Best UI/UX Development Service
                </h4>
                <Link to="#" className="btn-1 btn-sm btn-circle">
                  Read More
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="service-all-2 text-center mt-5">
            <Link to="/services" className="btn-1 btn-circle">
              See All
              <FaAnglesRight className="fa fa-angle-double-right" />
            </Link>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default OurServices;
