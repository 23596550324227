import { Col, Container, Image, Row } from 'react-bootstrap';

import benifit1 from '../assets/images/icon/benefit-1.png';
import benifit2 from '../assets/images/icon/benefit-2.png';
import benifit3 from '../assets/images/icon/benefit-3.png';
import bg_review from '../assets/images/bg/bg-review-1.png';
import { FaLaptopCode } from 'react-icons/fa6';

const OurFeatures = () => {
  return (
    <div className="benefit-area why-area-pb pos-rel mb-5">
      <Container>
        <Row className="benefit-wpr">
          <Col lg={6}>
            <div className="benefit-left">
              <Image src={bg_review} className="review-shape round-move" />
              <Row className="benefit-opt">
                <Col lg={6}>
                  <div className="benefit-opt-left element-center">
                    <div className="benefit-single benefit-one">
                      <i>
                        <Image src={benifit1} alt="no image" />
                      </i>
                      <h4 className="heading-4">Maintenance & support</h4>
                      <p style={{ textAlign: 'justify' }}>
                        We provide 24*7 maintenance & support service for all
                        our clients to resolve all the issues in real-time.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="benefit-opt-right">
                    <div className="benefit-wrap">
                      <div className="benefit-single benefit-two mb-3">
                        <i>
                          <Image src={benifit2} alt="no image" />
                        </i>
                        <h4 className="heading-4 mb-0">Software Consulting</h4>
                      </div>
                      <div className="benefit-single benefit-two">
                        <i>
                          <Image src={benifit3} alt="no image" />
                        </i>
                        <h4 className="heading-4 mb-0">Best Services</h4>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="benefit-right">
              <span className="hero-sub-title text-grad mb-10">
                OUR FEATURES
              </span>
              <h2 className="heading-regular mb-30">
                Best performance and awesome features
              </h2>
              <p className="mb-40">
                Run your business the way you want, not the way your software
                tells you to. With the right packaged or custom software
                applications, system integrations, and support, we can help you
                bridge your business software with operational success
              </p>
              <ul className="why-list">
                <li>
                  <FaLaptopCode className="feature-ico" />
                  Realtime Best Technical Solutions
                </li>
                <li>
                  <FaLaptopCode className="feature-ico" />
                  Project Initialization
                </li>
                <li>
                  <FaLaptopCode className="feature-ico" />
                  Dynamic Web Application
                </li>
                <li>
                  <FaLaptopCode className="feature-ico" />
                  Software Integration
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default OurFeatures;
